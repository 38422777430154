<template>
  <ion-page>
    <ion-content :fullscreen="true">
        <ion-grid>
            <div class="control-head">
                <div class="return" on-touch="returnSelect" @click="returnSelect"><ion-icon src="/assets/icon/arrow-back.svg"></ion-icon></div>
            </div>
            <ion-list>
                <div v-if="currentDir != null" class="folder-div">
                    <div on-touch="loadFolders(null)" @click="loadFolders(null)">
                        <ion-label>
                            <p>..</p>
                        </ion-label>
                    </div>
                </div>
                <div v-for="file in files" :key="file.id">
                    <div v-if="file.type=='folder'" on-touch="loadFolders(file.id)" @click="loadFolders(file.id)" class="folder-div">
                        <ion-label>
                            <p>{{file.name}}</p>
                        </ion-label>
                    </div>
                </div>
                <div class="folder-div">
                    <div on-touch="isOpenCreate = true" @click="() => isOpenCreate = true">
                        <ion-label>
                            <p>Створити нову</p>
                        </ion-label>
                    </div>
                </div>
            </ion-list>
        </ion-grid>
        <ion-footer class="stream-start">
            <div class="upp-file">
                <button type="button" class="button-native ion-color ion-color-primary button-type">
                    Додати відео/фото/аудіо
                </button>
                <input type="file" @change="upload">
            </div>
        </ion-footer>
    </ion-content>
      <ion-modal :is-open="isOpenLoad" @didDismiss="isOpenLoad = false">
          <ion-list style="margin-top: 30%;">
              <ion-list-header>
                  <ion-label style="text-align: center">Йде завантаження</ion-label>
              </ion-list-header>
              <ion-progress-bar :value="progress"></ion-progress-bar>
          </ion-list>
      </ion-modal>
      <ion-modal :is-open="isOpenCreate" @didDismiss="isOpenCreate = false">
          <ion-list style="margin-top: 30%;">
              <ion-row>
                  <ion-col>
                      <ion-item>
                          <ion-label position="stacked">Назва</ion-label>
                          <ion-input v-model="folderName"></ion-input>
                      </ion-item>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="6">
                      <button type="button" on-touch="isOpenCreate = false" class="button-native ion-color ion-color-primary button-type" @click="() => isOpenCreate = false">
                          Закрити
                      </button>
                  </ion-col>
                  <ion-col size="6">
                      <button type="button" on-touch="createFolder" class="button-native ion-color ion-color-primary button-type" @click="createFolder">
                          Створити
                      </button>
                  </ion-col>
              </ion-row>
          </ion-list>
      </ion-modal>
  </ion-page>
</template>

<script>
import { IonPage, IonGrid, IonRow, IonCol, IonContent, IonModal, IonIcon } from '@ionic/vue';
import {makeid} from "../constant/api";
import axios from "axios";

const API_URL = 'https://development.ukraine-inform.com';

export default  {
    name: 'Load',
    components: { IonGrid, IonContent, IonPage, IonModal, IonRow, IonCol, IonIcon },
    data() {
        return {
            isOpenLoad: false,
            progress: 0,
            badUpload: 0,
            currentDir: null,
            files: [],
            isOpenCreate: false,
            folderName: '',
        }
    },
    created() {
        this.loadFolders(null);
    },
    methods: {
        returnSelect() {
            window.location.href = '/select';
        },
        addName(event) {
            console.log(event.target.value);
            this.folderName = event.target.value;
            console.log(this.folderName);
        },
        createFolder() {
            const self = this;
            this.loadingFolders = true;
            let input = document.querySelector('.native-input.sc-ion-input-md');
            console.log(input);
            console.log(input.value);
            var url = 'https://development.ukraine-inform.com/folders/add-folders/' + (localStorage.getItem('user') || '') + '?name='+input.value + '&folder=' + (this.currentDir ? this.currentDir : '');
            axios({
                method: 'GET',
                url: url,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                }
            }).then(res => {
                console.log(res);
                self.loadFolders(self.currentDir);
            }).catch(error => {
                console.log(error);
            });
        },
        loadFolders(dir) {
            const self = this;
            this.currentDir = dir;
            var url = 'https://development.ukraine-inform.com/folders/get-all-folders/' + (localStorage.getItem('user') || '');
            if(dir)
                url = url + '?folder='+dir;
            axios({
                method: 'GET',
                url: url,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                }
            }).then(res => {
                self.files = res.data;
            });
        },
        upload($event) {
            var file = {
                id: makeid(),
                file: $event.target.files[0],
                progress: 0,
                currentDir: null
            };
            this.uploadFile(file);
            this.isOpenLoad = true;
            self.progress = 0;
        },
        uploadFile: function(file) {
            const self = this;
            var chunks = [];
            var size = 1024 * 1024 * 3;
            console.log('filesize: ' + file.file.size);
            console.log('size: ' + size);
            var chunksCount = Math.ceil(file.file.size / size);
            console.log('chunksCount: ' + chunksCount);
            for (let i = 0; i < chunksCount; i++) {
                chunks.push(file.file.slice(
                    i * size, Math.min(i * size + size, file.file.size), file.file.type
                ));
            }
            self.uploadChunk(0, chunksCount, chunks, file);
        },
        uploadChunk: function (i, chunksCount, chunks, file) {
            const self = this;
            const formData = new FormData();
            formData.append('name', file.file.name);
            if(file.currentDir)
                formData.append('folder', file.currentDir);
            formData.append('id', file.id);

            formData.append('key', i);
            formData.append('file', chunks[i]);

            if(i == chunksCount-1)
                formData.append('last', true);

            axios({
                method: 'POST',
                url: `${API_URL}/folders/upload-file/` + (localStorage.getItem('user') || ''),
                data: formData,
                headers: {'content-type': 'multipart/form-data'}
            }).then(function (response) {
                console.log(response);
                if (i == chunksCount-1) {
                    self.isOpenLoad = false;
                } else {
                    self.progress = ((100/chunksCount) * i)/100;
                    self.uploadChunk(i+1, chunksCount, chunks, file);
                }
                self.badUpload = 0;
            }).catch(function (error) {
                console.log(error);
                self.badUpload++;
                if(self.badUpload<20) {
                    console.log('try reload');
                    self.uploadChunk(i, chunksCount, chunks, file);
                } else {
                    alert('Your connection bad!');
                    self.badUpload = 0;
                    self.isOpenLoad = false;
                }
            });
        },
    }
}
</script>

<style>
.upp-file input {
    width: 99%;
    position: absolute;
    top: 0;
    left: 0;
    height: 58px;
    opacity: 0;
}
.folder-div {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}
.control-head {
    position: fixed;
    bottom: 15px;
    z-index: 999;
    left: 15px;
    font-size: 36px;
    background: #0037ffad;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    text-align: center;
    padding-top: 4px;
}
</style>
